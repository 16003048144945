import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Text } from '../Ui';

const TextWrapper = styled.div`
  padding: 10px 0;
  border-top: 1px solid ${props => props.border || 'transparent'};
  border-bottom: 1px solid ${props => props.border || 'transparent'};
`;

function HomeText() {
  const homecontent = useSelector(state => state.cms.home);
  const title_home = homecontent[0].contents.find(e => e.name === 'title_Home');
  const style = title_home && JSON.parse(title_home?.style);

  return title_home ? (
    <TextWrapper border={style?.border}>
      <Text bold align='center' color={title_home.text_color}>
        {title_home?.i18l.title}
      </Text>
      {title_home?.i18l.content && title_home?.i18l.content !== '<p>&nbsp;</p>' && (
        <Text align='center' className='mt-10' color={title_home.text_color}>
          {title_home?.i18l.content}
        </Text>
      )}
    </TextWrapper>
  ) : null;
}

export default HomeText;
