import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal, Loader, Col, Flex, Box, Text, Wrapper } from '../../components/Ui';
import Fade from '../../components/Fade/Fade';
import { removeFromCart, addToWishlist } from '../../store/actions/wishlist';
import { orderProduct, clearOrder, closeOrderModal } from '../../store/actions/products';
import { getUserInfo } from '../../store/actions/user';
import Recensione from '../../components/recensione';
import ProductInline from '../../components/product/ProductInline';
import Product from '../../components/product';
import ReactShadowScroll from 'react-shadow-scroll';
//provisorio
import Checkout from '../../components/Checkout';
import ProductDetails from '../../components/product/productDetails';

const Cart = props => {
  const dispatch = useDispatch();

  const [isInCheckout, setIsInCheckout] = useState(false);
  const [isReviews, setIsReviews] = useState(false);

  const cart = useSelector(state => state.user.user.cart);
  const userInfo = useSelector(state => state.user.user.userinfo);
  const {
    product,
    orderModal,
    loading: singleProductLoading,
    newOrder,
  } = useSelector(state => state.product);
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});

  const removeFromCartHandler = (productId, id) => {
    dispatch(removeFromCart(productId, id));
  };

  const moveToWishlistHandler = (productId, id) => {
    dispatch(removeFromCart(productId, id));
    dispatch(addToWishlist(productId, id));
  };

  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (cart) {
      let tot = 0;
      cart.forEach(prod => {
        tot += prod.points * prod.cart_quantity;
      });

      setTotal(tot);
    }
  }, [cart]);

  return (
    <>
      <Fade>
        <Wrapper>
          {cart?.length > 0 ? (
            <>
              <Text as='h1' size={40} className='mb-20'>
                {option?.menuSettings?.cart?.label}
              </Text>
              {cart.map((prod, prodIndex) => (
                <ProductInline
                  key={prod.id}
                  product={prod}
                  catalogId={prod.catalog_id}
                  setIsReviews={setIsReviews}
                />
              ))}
            </>
          ) : (
            cart === undefined ||
            (cart?.length === 0 && (
              <div style={{ textAlign: 'center' }}>
                <Text as='h1' size={40} className='mb-20'>
                  Carrello
                </Text>
                <Text size={18}>Il tuo carrello è vuoto.</Text>
              </div>
            ))
          )}

          {cart && cart.length > 0 && !isInCheckout && (
            <Box className='mt-20' width='100%' padding='20px'>
              {userInfo?.score - total >= 0 ? (
                <>
                  <Text as='h4' size={20} className='mb-10' text_box>
                    Hai punti a sufficienza.
                  </Text>
                  <Text className='mb-20' text_box>
                    Hai accumulato i punti necessari: puoi ordinare i tuoi premi preferiti!
                  </Text>
                  <Link to='/checkout' style={{ display: 'inline-block' }}>
                    <Button active>Richiedi premio</Button>
                  </Link>
                </>
              ) : (
                <>
                  <Text as='h4' size={20} className='mb-10' color='#d02c2f'>
                    Non hai punti a sufficienza.
                  </Text>
                  <Text text_box>
                    Continua ad accumulare punti e ottieni i tuoi premi preferiti.
                  </Text>
                </>
              )}
            </Box>
          )}
        </Wrapper>
      </Fade>
      <Modal
        show={orderModal.isOpen}
        close={() => dispatch(closeOrderModal())}
        isFixed={!isReviews}
      >
        {singleProductLoading && !isReviews ? (
          <Loader show={singleProductLoading} />
        ) : isReviews ? (
          <Recensione product={product} />
        ) : (
          <ReactShadowScroll
            style={{ width: '100%', maxHeight: '80vh' }}
            isShadow={false}
            scrollColor={'#cccccc'}
            scrollColorHover='gray'
            styleSubcontainer={{ overflowX: 'hidden' }}
          >
            <ProductDetails product={orderModal?.product} setIsReviews={setIsReviews} />
          </ReactShadowScroll>
        )}
      </Modal>
    </>
  );
};

export default Cart;
