import React from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

const stringToHTML = function (str) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(str, 'text/html');
  var script = [...doc.querySelectorAll('script')];

  var scripts = script.map(item => {
    let attributs = item.getAttributeNames().reduce((at, current) => {
      at[current] = item.getAttribute(current);
      return at;
    }, {});
    return <script {...attributs}>{item.textContent}</script>;
  });

  return scripts;
};

const GenericScripts = ({ scripts }) => {
  const [state, setState] = useState(false);
  const router = useHistory();

  useEffect(() => {
    if (!scripts) return;
    let script;
    let s;
    let p = parse(scripts);
    if (typeof p === 'string') {
      return;
    }
    if (!Array.isArray(p)) {
      p = [p];
    }

    if (Array.isArray(p)) {
      p.map(item => {
        if (item.props?.src) {
          script = document.createElement('script');
          script.setAttribute('charset', item.props?.charSet);
          script.setAttribute('type', item.props?.type);
          script.setAttribute('src', item.props?.src);
          document.getElementById('container_script').appendChild(script);
        }

        if (item.props?.dangerouslySetInnerHTML?.__html) {
          if (script) {
            script.addEventListener('load', () => {
              s = document.createElement('script');
              s.innerHTML = item.props?.dangerouslySetInnerHTML?.__html;
              document.getElementById('container_script').appendChild(s);
            });
          } else {
            s = document.createElement('script');
            s.innerHTML = item.props?.dangerouslySetInnerHTML?.__html;
            document.getElementById('container_script').appendChild(s);
          }
        }
      });
    }

    return () => {
      /* @ts-ignore */
      while (document.getElementById('container_script').firstChild) {
        /* @ts-ignore */
        document
          .getElementById('container_script')
          /* @ts-ignore */
          .removeChild(document.getElementById('container_script').firstChild);
      }
    };
  }, [router.location.pathname]);
  return <div id='container_script'></div>;
};

export default GenericScripts;
