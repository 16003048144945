export const theme = {
  primary: '#154280',
  secondary: '#b1b1b1',
  default_color: '#161615',
  default_text_color: '#fff',
  colore_testo_bottoni: '#fff',
  bg_bottoni_secondari: '#808080',
  colore_testo_bottoni_secondari: '#fff',
  colore_bordo_box: '#bdccd4',
  bg_bottoni: '#1e8015',
  color_link: '#808080',
  text_click: '#00ff',
  colore_link: '#009',
  bg_header: '',
  colore_testo_hover_menu_interno: '#fff',
  colore_testo_alert: '#fff000',
  colore_bordo_alert: '#ff0000',
  colore_testo_menu_interno: '#000000',
  bg_menu_interno: '#f4f6f9',
  bg_hover_menu_interno: '#808080',
  colore_testo_menu_esterno: '#fff',
  colore_testo_hover_menu_esterno: '#1e8015',
  colore_testo_campi: '#000000',
  colore_checkbox_attiva: '#00ff',
  colore_checkbox_disattiva: '#fff',
  borderCode: '#cccccc',
  color_icon_prd: ' #d02c2f',
  bg_grey_light: '#f4f6f9',
  bg_alert: '#fff',
  bg_btn: '#f4f6f9',
  bg_general: '#fff',
  bg_box: '#fff',
  colore_testo_box: '#000',
  bordo_bottone: '#ADB4BF',
  bg_footer_basso: '#fff',
  bordo_bottone_descrizione: '#cccccc',
  bg_bottone_descrizione: 'transparent',
  border_radius_generale_grande: '10px',
  border_radius_generale_piccolo: '10px',
  bordersColor: {
    btn: '#bdccd4',
  },
  //modal: '#fff',
  intialColorLoader: '#000',
  bgboard: '#f4f4f4',
  bgcode: '#f4f4f4',
  bgOption: '#e6e6e6',

  btnRound: true,
  btnTextColor: '#fff',
  footer_bg: '#b3b3b3',
  footer_desktop_height: 140,
  footer_mobile_height: 221,
  footer_logo_size: 200,
  header_desktop_height: 93,
  header_mobile_height: 86,
  text_primary: '#000000',
  fontSource: 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,900&display=swap',
  fontFamily: 'arial',

  text: {
    primary: '#4a4a4a',
    secondary: '#fff',
    tertiary: '#cccccc',
  },
  input: {
    bg: '#f4f6f9',
    label: '#fff',
    labelModal: '#333333',
    error: '#ff0000',
  },
  isrect: false,
  textStyles: {
    vscream: {
      tag: 'h1',
      fontSize: ['32px', '42px', '52px'],
      fontWeight: 100,
      lineHeight: ['36px', '50px', '60px'],
    },
    vbody: {
      tag: 'p',
      fontSize: ['22px', '22px', '22px'],
      fontWeight: 100,
      lineHeight: ['28px', '28px', '28px'],
    },
    scream: {
      tag: 'p',
      fontSize: ['16px', '18px', '20px'],
      fontWeight: 100,
      lineHeight: ['18px', '20px', '22px'],
    },
    body: {
      tag: 'p',
      fontSize: ['12px', '12px', '14px'],
      fontWeight: 100,
      lineHeight: ['14px', '14px', '16px'],
    },
    bscream: {
      tag: 'p',
      fontSize: ['24px', '26px', '30px'],
      fontWeight: 300,
      lineHeight: ['28px', '30px', '34px'],
    },
    bscreamsmall: {
      tag: 'p',
      fontSize: ['24px', '24px', '24px'],
      fontWeight: 300,
      lineHeight: ['28px', '28px', '28px'],
    },
    bbody: {
      tag: 'p',
      fontSize: ['20px', '18px', '18px'],
      fontWeight: 100,
      lineHeight: ['24px', '24px', '24px'],
    },
    label: {
      tag: 'p',
      fontSize: ['15px', '18px', '18px'],
      fontWeight: 100,
      lineHeight: ['18px', '24px', '24px'],
    },
    middlescream: {
      tag: 'p',
      fontSize: ['1.5rem', '2rem'],
      fontWeight: 100,
      lineHeight: ['1.75rem', '2.25rem'],
    },
    title: {
      tag: 'p',
      fontSize: ['24px', '30px', '34px'],
      fontWeight: 100,
      lineHeight: ['28px', '34px', '40px'],
    },
    mediumTitle: {
      tag: 'h4',
      fontSize: ['16px', '18px', '20px'],
      fontWeight: 100,
      lineHeight: ['20px', '22px', '25px'],
    },
    largTitle: {
      tag: 'h4',
      fontSize: ['18px', '20px', '24px'],
      fontWeight: 100,
      lineHeight: ['22px', '26px', '28px'],
    },
    bigTitle: {
      tag: 'h1',
      fontSize: ['30px', '50px', '70px'],
      fontWeight: 100,
      lineHeight: ['30px', '50px', '70px'],
    },
    text: {
      tag: 'p',
      fontSize: ['16px', '16px', '18px'],
      fontWeight: 100,
      lineHeight: ['24px', '26px', '28px'],
    },
    textSlider: {
      tag: 'p',
      fontSize: ['12px', '12px', '11px'],
      fontWeight: 100,
      lineHeight: ['16px', '16px', '18px'],
    },
    textDetails: {
      tag: 'p',
      fontSize: ['12px', '12px', '14px'],
      fontWeight: 100,
      lineHeight: ['14px', '16px', '18px'],
    },
    smallLink: {
      tag: 'p',
      fontSize: ['14px', '14px', '14px'],
      textDecoration: 'underline',
      fontWeight: 100,
      lineHeight: ['14px', '14px', '14px'],
    },
    underline: {
      textDecoration: 'underline',
    },
    infolabel: {
      tag: 'p',
      fontSize: ['12px', '16px', '16px'],
      lineHeight: ['16px', '20px', '20px'],
      fontWeight: 100,
    },
    middlesc: {
      tag: 'p',
      fontSize: ['26px', '42px', '42px'],
      lineHeight: ['30px', '46px', '46px'],
      fontWeight: 300,
    },
    middlep: {
      tag: 'p',
      fontSize: ['18px', '22px', '22px'],
      lineHeight: ['22px', '26px', '26px'],
      fontWeight: 400,
    },
    textSize: {
      tag: 'p',
      fontSize: ['20px', '25px', '35px'],
      lineHeight: ['24px', '28px', '38px'],
      fontWeight: 400,
    },
    titleGame: {
      tag: 'p',
      fontSize: ['36px', '46px', '56px'],
      fontWeight: 100,
      lineHeight: ['40px', '50px', '60px'],
    },
    descGame: {
      tag: 'p',
      fontSize: ['18px', '21px', '24px'],
      fontWeight: 100,
      lineHeight: ['22px', '24px', '28px'],
    },
    question: {
      tag: 'p',
      fontSize: ['18px', '20px', '20px'],
      fontWeight: 400,
      lineHeight: ['21px', '24px', '24px'],
    },
    answer: {
      tag: 'p',
      fontSize: ['18px', '18px', '18px'],
      fontWeight: 300,
      lineHeight: ['21px', '22px', '22px'],
    },
    navLink: {
      tag: 'p',
      fontSize: ['16px', '16px', '16px'],
      fontWeight: 300,
      lineHeight: ['20px', '20px', '20px'],
    },
    navLinkSmall: {
      tag: 'p',
      fontSize: ['12px', '12px', '12px'],
      fontWeight: 300,
      lineHeight: ['16px', '16px', '16px'],
    },
  },
  fontSizes: [11, 13, 14, 15, 16, 18, 20, 21, 22, 24, 26, 28, 32, 36, 52],
  breakpoints: ['48em', '64em', '48em'],
  awesomegrid: {
    mediaQuery: 'only screen',
    columns: {
      xs: 4,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    gutterWidth: {
      xs: 1,
      sm: 1,
      md: 1.5,
      lg: 1.5,
      xl: 1.5,
    },
    paddingWidth: {
      xs: 1,
      sm: 1,
      md: 1.5,
      lg: 1.5,
      xl: 1.5,
    },
    container: {
      xs: 'full', // 'full' = max-width: 100%
      sm: 'full', // 'full' = max-width: 100%
      md: 'full', // 'full' = max-width: 100%
      lg: 90, // max-width: 1440px
      xl: 90, // max-width: 1440px
    },
    breakpoints: {
      xs: 1,
      sm: 48, // 768px
      md: 64, // 1024px
      lg: 90, // 1440px
      xl: 120, // 1920px
    },
  },
};

export const configTheme = appConfig => {
  if (appConfig !== undefined) {
    if (appConfig.design !== undefined) {
      if (appConfig.design.template_props !== undefined) {
        const {
          color_primary,
          color_secondary,
          isrect,
          button_textcolor,
          button_round,
          footer_desktop_height,
          footer_mobile_height,
          header_desktop_height,
          header_mobile_height,
          text_color_primary,
          font_source,
          font_family,
          logo_size,
          footer_logo_size,
          input_bg,
          colore_testo_generale,
          color_link,
          text_click,
          borderCode,
          bg_bottoni,
          colore_testo_bottoni,
          bg_btn,
          default_text_color,
          bg_footer,
          bg_generale,
          bg_box,
          colore_testo_box,
          bg_bottoni_secondari,
          colore_testo_bottoni_secondari,
          colore_bordo_box,
          colore_icone_prodotti,
          bg_color_alert,
          colore_testo_hover_menu_interno,
          colore_testo_alert,
          colore_bordo_alert,
          colore_testo_menu_interno,
          bg_menu_interno,
          bg_hover_menu_interno,
          colore_testo_menu_esterno,
          colore_testo_hover_menu_esterno,
          colore_testo_campi,
          colore_checkbox_attiva,
          colore_checkbox_disattiva,
          colore_link,
          bg_footer_basso,
          bordo_bottone_descrizione,
          bg_bottone_descrizione,
          border_radius_generale_piccolo,
          border_radius_generale_grande,
          fontWurth,
          bg_header,
          bordo_bottone,
        } = appConfig.design.template_props;

        if (bordo_bottone) {
          theme.bordo_bottone = `${bordo_bottone}`;
        }
        if (colore_link) {
          theme.colore_link = `${colore_link}`;
        }
        if (bg_header) {
          theme.bg_header = `${bg_header}`;
        }
        if (border_radius_generale_grande) {
          theme.border_radius_generale_grande = `${border_radius_generale_grande}`;
        }
        if (border_radius_generale_piccolo) {
          theme.border_radius_generale_piccolo = `${border_radius_generale_piccolo}`;
        }
        if (bg_bottone_descrizione) {
          theme.bg_bottone_descrizione = `${bg_bottone_descrizione}`;
        }
        if (bordo_bottone_descrizione) {
          theme.bordo_bottone_descrizione = `${bordo_bottone_descrizione}`;
        }
        if (bg_footer_basso) {
          theme.bg_footer_basso = `${bg_footer_basso}`;
        }
        if (colore_checkbox_attiva) {
          theme.colore_checkbox_attiva = `${colore_checkbox_attiva}`;
        }
        if (colore_checkbox_disattiva) {
          theme.colore_checkbox_disattiva = `${colore_checkbox_disattiva}`;
        }
        if (colore_bordo_alert) {
          theme.colore_bordo_alert = `${colore_bordo_alert}`;
        }
        if (colore_testo_campi) {
          theme.colore_testo_campi = `${colore_testo_campi}`;
        }
        if (colore_testo_hover_menu_esterno) {
          theme.colore_testo_hover_menu_esterno = `${colore_testo_hover_menu_esterno}`;
        }
        if (colore_testo_menu_esterno) {
          theme.colore_testo_menu_esterno = `${colore_testo_menu_esterno}`;
        }
        if (bg_menu_interno) {
          theme.bg_menu_interno = `${bg_menu_interno}`;
        }
        if (bg_hover_menu_interno) {
          theme.bg_hover_menu_interno = `${bg_hover_menu_interno}`;
        }
        if (colore_testo_hover_menu_interno) {
          theme.colore_testo_hover_menu_interno = `${colore_testo_hover_menu_interno}`;
        }
        if (colore_testo_menu_interno) {
          theme.colore_testo_menu_interno = `${colore_testo_menu_interno}`;
        }
        if (colore_testo_alert) {
          theme.colore_testo_alert = `${colore_testo_alert}`;
        }
        if (colore_icone_prodotti) {
          theme.color_icon_prd = `${colore_icone_prodotti}`;
        }
        if (bg_color_alert) {
          theme.bg_alert = `${bg_color_alert}`;
        }
        if (colore_bordo_box) {
          theme.colore_bordo_box = `${colore_bordo_box}`;
        }
        if (colore_testo_bottoni_secondari) {
          theme.colore_testo_bottoni_secondari = `${colore_testo_bottoni_secondari}`;
        }
        if (bg_bottoni_secondari) {
          theme.bg_bottoni_secondari = `${bg_bottoni_secondari}`;
        }
        if (bg_box) {
          theme.bg_box = `${bg_box}`;
        }
        if (colore_testo_box) {
          theme.colore_testo_box = `${colore_testo_box}`;
        }
        if (bg_generale) {
          theme.bg_general = `${bg_generale}`;
        }
        if (bg_bottoni) {
          theme.bg_bottoni = `${bg_bottoni}`;
        }
        if (colore_testo_bottoni) {
          theme.colore_testo_bottoni = `${colore_testo_bottoni}`;
        }
        if (default_text_color) {
          theme.default_text_color = `${default_text_color}`;
        }
        if (colore_testo_generale) {
          theme.default_color = `${colore_testo_generale}`;
        }
        if (color_primary) {
          theme.primary = `${color_primary}`;
        }

        if (bg_footer) {
          theme.footer_bg = `${bg_footer}`;
        }
        if (text_click) {
          theme.text_click = `${text_click}`;
        }
        if (bg_btn) {
          theme.bg_btn = `${bg_btn}`;
        }
        if (text_color_primary) {
          theme.text_primary = `${text_color_primary}`;
        }
        if (isrect) {
          theme.isrect = isrect;
        }
        if (typeof button_round !== 'undefined') {
          theme.btnRound = button_round;
        }
        if (button_textcolor) {
          theme.btnTextColor = button_textcolor;
        }
        if (footer_desktop_height) {
          theme.footer_desktop_height = footer_desktop_height;
        }
        if (footer_mobile_height) {
          theme.footer_mobile_height = footer_mobile_height;
        }
        if (header_desktop_height) {
          theme.header_desktop_height = header_desktop_height;
        }
        if (header_mobile_height) {
          theme.header_mobile_height = header_mobile_height;
        }
        if (font_source) {
          theme.fontSource = `${font_source}`;
        }
        if (font_family) {
          theme.fontFamily = `${font_family}`;
        }
        if (logo_size) {
          theme.logo_size = `${logo_size}`;
        }
        if (footer_logo_size) {
          theme.footer_logo_size = footer_logo_size;
        }
        if (input_bg) {
          theme.input.bg = input_bg;
        }
      }
    }
  }
};
