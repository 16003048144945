import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Footer from '../../components/Footer/Footer';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Wrapper } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import Header from '../../components/Header/Header';
import { Route } from 'react-router-dom';
import SigninForm from '../../components/SigninForm/SigninForm';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import RecoveryPasswordForm from '../../components/RecoveryPasswordForm';
import AuthText from '../../components/AuthText';
import ViewerSlider from '../../components/ViewerSlider/ViewerSlider';
import HomeBlocks from '../../components/HomeBlocks';
import { useDispatch, useSelector } from 'react-redux';
import { showAuthForm, signinSuccess } from '../../store/actions/auth';
import { useEffect } from 'react';
import useQuery from '../../hooks/useQuery';
import { appQuery } from '../../store/actions/app';
import GenericScripts from '../../components/GenericScripts/GenericScripts';

const AuthTemplate = props => {
  // const [isLogin, setIsLogin] = useState(false);

  const { from } = props.location.state || { from: '/' };
  const isLogin = useSelector(state => state.auth.show_auth_form);
  const dispatch = useDispatch();
  const { auth } = useParams();
  const query = useQuery();
  const queryApp = query.get('app');
  const open = query.get('open');
  const appQueryParam = useSelector(state => state.app?.appQuery);
  const visible = useSelector(state => state.app?.config?.visible);
  const script = useSelector(state => state.app?.config?.i18l);
  const history = useHistory();

  useEffect(() => {
    auth === 'register' && dispatch(showAuthForm(true));
  }, []);

  useEffect(() => {
    const localQueryApp = localStorage.getItem('queryApp');

    if (queryApp || localQueryApp) {
      if (queryApp) {
        dispatch(appQuery(queryApp));
      } else {
        dispatch(appQuery(localQueryApp));
      }
      localStorage.setItem('queryApp', true);
    }
  }, [queryApp]);
  useEffect(() => {
    !visible && history.location.pathname === '/auth/register' && history.push('/auth/login');
  }, [visible, history.location.pathname]);
  return (
    <CustomWrapper>
      {!appQueryParam && <Header />}
      <Wrapper>
        {(isLogin || open === 'true') && (
          <FormContainer visible={visible && history.location.pathname === '/auth/register'}>
            <Route path='/auth/login' exact>
              <SigninForm whereToGo={from} />
            </Route>
            <Route path='/auth/register' exact>
              <RegisterForm />
            </Route>
            <Route path='/auth/recovery-password' exact>
              <RecoveryPasswordForm />
            </Route>
            {props.children}
          </FormContainer>
        )}
      </Wrapper>
      <SidesWrapper>
        <Wrapper>
          <ViewerSlider />
        </Wrapper>
        <Wrapper>
          <AuthText />
        </Wrapper>
        <HomeBlocks />
        {!queryApp && <GenericScripts scripts={script?.gdpr?.extra} />}
      </SidesWrapper>
      {!appQueryParam && <Footer />}
    </CustomWrapper>
  );
};

const CustomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const SidesWrapper = styled.div`
  display: block;
  margin-bottom: 50px;
  margin-left: 0;
  margin-right: 0;
  flex-grow: 1;
  //margin-top: 103px;
  position: relative;
  ${respondTo.sm`
    margin-top: 0px;
   /*  margin-left: 15px;
    margin-right: 15px; */
  `}
`;

const FormContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bg_box};
  flex: 0 0 100%;
  padding: 30px 15px;
  flex-direction: column;
  justify-content: space-between;

  z-index: 1;
  position: absolute;
  right: 0;
  top: 1px;
  left: 0;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colore_bordo_box};
  ${respondTo.sm`
    flex: 0 0 400px;
    width: 550px;
    left: auto;
    top: -13px;
    z-index: 999999999999999999999999999999999999999999
  `};
`;

AuthTemplate.propTypes = {
  logo: PropTypes.string,
  titlepage: PropTypes.string,
  children: PropTypes.any,
};

export default withRouter(AuthTemplate);
