import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Text } from '../Ui';
import styled from 'styled-components';
function Meccanica() {
  const { contents } = useSelector(state => state.cms.meccanica[0]);

  return contents?.map(
    ({ pdf, text_color, background_color, style, img, i18l: { title, content } }) => {
      const styles = style && JSON.parse(style);
      const textWidth = styles?.width;
      if (styles) {
        delete styles?.width;
      }
      return (
        <CustomBox
          className='mb-20'
          text_color={text_color}
          style={{ backgroundColor: background_color, ...styles }}
        >
          <div className='image'>
            <img src={img} alt='' />
            <Text bold type='descGame' className='title' size={34} style={{ width: textWidth }}>
              {title}
            </Text>
          </div>
          <Box padding='20px 5%' style={{ border: 'none', backgroundColor: 'transparent' }}>
            <Text>{content}</Text>
          </Box>
        </CustomBox>
      );
    },
  );
}
const CustomBox = styled(Box)`
  border: none;

  padding: 0;
  background-color: transparent;
  .image {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
      display: block;
    }
  }
  .title {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: 20%;
    color: ${({ text_color }) => text_color};
  }
  .pdf {
    font-weight: bold;
    font-size: 18px;
    display: inline-block;
    margin: 15px 0;
    text-decoration: underline;
  }
`;

export default Meccanica;
