import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';

const LogoContainer = styled.div`
  //max-width: ${props => (props.theme.logo_size ? props.theme.logo_size + 'px' : 'auto')};
  margin-right: 30px;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 'auto')};
  a {
    outline: none;
  }
  ${respondTo.sm`
       margin-right: 60px;
  `};
`;

const Logo = props => {
  const { nolink, logo } = props;

  let logoContent = null;

  if (nolink) {
    logoContent = <img src={logo} alt='' />;
  } else {
    logoContent = (
      <Link to='/'>
        <img src={logo} alt='' />
      </Link>
    );
  }

  return <LogoContainer {...props}>{logoContent}</LogoContainer>;
};

Logo.propTypes = {
  nolink: PropTypes.bool,
  logo: PropTypes.string,
};

export default Logo;
