import * as actionTypes from '../actions/actionTypes';

const initialState = {
  intro: [],
  loadingintro: true,
  errorintro: false,
  footer: [],
  premi: [],
  authentication: [],
  profile: [],
  contact: [],
  howItWorks: [],
  home: [],
  meccanica: [],
  catalog: [],
  catalogTop: [],
  registerSchool: [],
  catalogBottom: [],
  tutorial: [],
  howToParticipate: [],
  game: [],
  advantages: [],
  loadingfooter: true,
  errorfooter: false,
  loadingPremi: true,
  errorPremi: false,
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CMS_INTRO_START:
      return {
        ...state,
        loadingintro: true,
      };
    case actionTypes.GET_CMS_INTRO_SUCCESS:
      return {
        ...state,
        loadingintro: false,
        intro: action.data,
      };
    case actionTypes.GET_CMS_INTRO_ERROR:
      return {
        ...state,
        loadingintro: false,
        errorintro: action.error,
      };
    case actionTypes.GET_CMS_FOOTER_START:
      return {
        ...state,
        loadingfooter: true,
      };
    case actionTypes.GET_CMS_FOOTER_SUCCESS:
      return {
        ...state,
        loadingfooter: false,
        footer: action.content,
      };
    case actionTypes.GET_CMS_FOOTER_ERROR:
      return {
        ...state,
        loadingfooter: false,
        errorfooter: action.error,
      };
    case actionTypes.GET_CMS_PREMI_START:
      return {
        ...state,
        loadingPremi: true,
      };
    case actionTypes.GET_CMS_PREMI_SUCCESS:
      return {
        ...state,
        loadingPremi: false,
        premi: action.content,
      };
    case actionTypes.GET_CMS_PREMI_ERROR:
      return {
        ...state,
        loadingPremi: false,
        errorPremi: action.error,
      };
    case actionTypes.GET_CMS_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        authentication: action.content,
      };
    case actionTypes.GET_CMS_HOW_TO_PARTICIPATE_SUCCESS:
      return {
        ...state,
        howToParticipate: action.content,
      };
    case actionTypes.GET_CMS_TOTURIAL_SUCCESS:
      return {
        ...state,
        tutorial: action.content,
      };
    case actionTypes.GET_CMS_CATALOGBOTTOM_SUCCESS:
      return {
        ...state,
        catalogBottom: action.content,
      };
    case actionTypes.GET_CMS_CATALOGTOP_SUCCESS:
      return {
        ...state,
        catalogTop: action.content,
      };
    case actionTypes.GET_CMS_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.content,
      };
    case actionTypes.GET_CMS_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.content,
      };
    case actionTypes.GET_CMS_HOW_IT_WORKS_SUCCESS:
      return {
        ...state,
        howItWorks: action.content,
      };
    case actionTypes.GET_CMS_HOME_SUCCESS:
      return {
        ...state,
        home: action.content,
      };
    case actionTypes.GET_CMS_MECCANICA_SUCCESS:
      return {
        ...state,
        meccanica: action.content,
      };
    case actionTypes.GET_CMS_CATALOG_SUCCESS:
      return {
        ...state,
        catalog: action.content,
      };
    case actionTypes.GET_CMS_GAME_SUCCESS:
      return {
        ...state,
        game: action.content,
      };
    case actionTypes.GET_CMS_ADVANTAGES_SUCCESS:
      return {
        ...state,
        advantages: action.content,
      };
    case actionTypes.GET_CMS_REGISTER_SCHOOL_SUCCESS:
      return {
        ...state,
        registerSchool: action.content,
      };
    default:
      return state;
  }
};

export default cmsReducer;
