import React from 'react';
import { Text, Wrapper } from '../../components/Ui';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';

function Tutorial() {
  const tutorial = useSelector(state => state.cms.tutorial);
  const isLogged = useSelector(state => state.auth.token);
  const home = useSelector(state => state.cms.home);
  const tutorial_content = tutorial?.[0]?.contents.find(el => el.name === 'tutorial');

  const headertitoli1 = home[0].contents.find(el => el.name === 'headertitoli1');
  return (
    <>
      {headertitoli1?.i18l.content && !isLogged && (
        <Text bold primary align='center'>
          {headertitoli1?.i18l.content}
        </Text>
      )}
      <Wrapper style={{ margin: '50px auto 100px auto' }}>
        {(tutorial_content?.i18l.title || tutorial_content?.img) && (
          <TutorialTop>
            {tutorial_content?.i18l.title && (
              <Text bold primary align='center'>
                {tutorial_content?.i18l.title}
              </Text>
            )}
            {tutorial_content?.img && <img src={tutorial_content?.img} alt='' />}
          </TutorialTop>
        )}
        {tutorial_content?.i18l.content && (
          <TutorialBottom>
            <Text as='p' style={{ padding: '0 15px' }}>
              {tutorial_content?.i18l.content}
            </Text>
          </TutorialBottom>
        )}
      </Wrapper>
    </>
  );
}
const TutorialTop = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  img {
    display: block;
    margin-top: 10px;
    width: 100%;
  }
`;
const TutorialBottom = styled.div`
  iframe {
    width: 100%;
    ${respondTo.sm`
    width:70%;
  `}
  }
`;

export default Tutorial;
