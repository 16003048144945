import * as actionTypes from './actionTypes';
import customAxios from '../../config/axios-refresh-token';
import { getUserInfo } from './user';

const toWishlistSuccess = wishlist => {
  return {
    type: actionTypes.TO_WISHLIST_SUCCESS,
    wishlist,
  };
};

export const addToWishlist = (productId, id, children_id) => {
  let endpoint = '/catalog/products/addtowishlist';
  const data = { products: [{ id: productId, catalog_id: id, children_id }] };
  return dispatch => {
    return customAxios.post(endpoint, data).then(result => {
      dispatch(toWishlistSuccess(result.data.wishlist));
    });
  };
};

export const removeFromWishlist = (productId, id) => {
  let endpoint = '/catalog/products/removefromwishlist';
  const data = { products: [{ id: productId, catalog_id: id }] };
  return dispatch => {
    return customAxios.post(endpoint, data).then(result => {
      if (result.status === 200) {
        dispatch(toWishlistSuccess(result.data.wishlist));
      }
    });
  };
};
export const movetolist = (productId, list) => {
  let endpoint = '/catalog/products/movetolist';
  const data = { product_id: productId, list };
  return dispatch => {
    return customAxios.post(endpoint, data).then(result => {
      if (result.status === 200) {
        dispatch(getUserInfo());
        //dispatch(toWishlistSuccess(result.data.wishlist));
      }
    });
  };
};

const toCartSuccess = cart => {
  return {
    type: actionTypes.TO_CART_SUCCESS,
    cart,
  };
};
const addToCartError = error => {
  return {
    type: actionTypes.TO_CART_ERROR,
    error,
  };
};
export const addToCartClearError = () => {
  return {
    type: actionTypes.TO_CART_CLEAR_ERROR,
  };
};

export const addToCart = (productId, id, children_id) => {
  let endpoint = '/catalog/products/addtocart';
  const data = { products: [{ id: productId, catalog_id: id, children_id }] };
  return dispatch => {
    return customAxios
      .post(endpoint, data)
      .then(result => {
        return dispatch(toCartSuccess(result.data.cart));
      })
      .catch(err => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = err;
        if (status === 400) {
          return dispatch(addToCartError(message));
        }
      });
  };
};
export const updateQteCart = (productId, id, qte, children_id) => {
  let endpoint = '/catalog/products/updatecartquantity';
  const data = { products: [{ id: productId, catalog_id: id, quantity: +qte, children_id }] };
  return dispatch => {
    return customAxios
      .post(endpoint, data)
      .then(result => {
        dispatch(getUserInfo());
      })
      .catch(err => {});
  };
};

export const removeFromCart = (productId, id) => {
  let endpoint = '/catalog/products/removefromcart';
  const data = { products: [{ id: productId, catalog_id: id }] };
  return dispatch => {
    return customAxios.post(endpoint, data).then(result => {
      if (result.status === 200) {
        dispatch(toCartSuccess(result.data.cart));
      }
    });
  };
};
