import * as actionTypes from './actionTypes';
import { getUserInfo } from './user';
import customaxios from '../../config/axios-refresh-token';
import { sendLogError } from './error';

// Products
const getProductsLoading = () => {
  return {
    type: actionTypes.GET_PRODUCTS_LOADING,
  };
};

export const setIdCatalog = id => {
  return {
    type: actionTypes.SET_ID_CATALOG,
    id,
  };
};
const getProductsSuccess = data => {
  return {
    type: actionTypes.GET_PRODUCTS_SUCCESS,
    products: data.products,
    total_pages: data.total_pages,
  };
};

const getAllProductsSuccess = products => {
  return {
    type: actionTypes.GET_ALL_PRODUCTS_SUCCESS,
    products,
  };
};

export const getAllProducts = (page = 1, limit = 18, id) => {
  let endpoint = `/catalog/products?page=${page}&limit=${limit}`;
  //let endpoint = '/catalog/products';
  return dispatch => {
    dispatch(getProductsLoading());
    return customaxios
      .get(endpoint, { params: { catalog_id: id } })
      .then(result => {
        if (result.status === 200) {
          return dispatch(getAllProductsSuccess(result.data));
        }
      })
      .catch(error => {});
  };
};

export const getProducts = (page = 1, filter, id, limit = 18) => {
  const dataToSend = {};
  if (filter) {
    //dataToSend.catalog_id = filter.area.value;
    dataToSend.macroCategory = filter.macroCategory.value;
    dataToSend.category = filter.category.value;
    dataToSend.brand = filter.brand.value;
    dataToSend.search = filter.search.value;
    dataToSend.points_from = filter.puntiDa.value;
    dataToSend.points_to = filter.puntiA.value;
  }

  let endpoint = `/catalog/search?page=${page}&limit=${limit}`;
  return dispatch => {
    dispatch(getProductsLoading());
    return customaxios
      .get(endpoint, { params: { ...dataToSend } })
      .then(result => {
        if (result.status === 200) {
          return dispatch(getProductsSuccess(result.data));
        }
      })
      .catch(error => {});
  };
};

// Single product
const getProductLoading = product => {
  return {
    type: actionTypes.GET_PRODUCT_LOADING,
  };
};

const getProductSuccess = product => {
  return {
    type: actionTypes.GET_PRODUCT_SUCCESS,
    product,
  };
};

export const getSingleProduct = (id, catalogId) => {
  let endpoint = `/catalog/products/${id}/get`;
  return dispatch => {
    dispatch(getProductLoading());
    customaxios
      .get(endpoint, { params: { catalog_id: catalogId } })
      .then(result => {
        if (result.status === 200) {
          dispatch(getProductSuccess(result.data));
        }
      })
      .catch(error => {});
  };
};

//add review
export const addReview = (review, id) => {
  let endpoint = `/catalog/products/${id}/review`;
  return dispatch => {
    return customaxios
      .post(endpoint, review)
      .then(result => {})
      .catch(error => {});
  };
};

// Order product
const orderProductStart = () => {
  return {
    type: actionTypes.ORDER_PRODUCT_START,
  };
};

const orderProductSuccess = () => {
  return {
    type: actionTypes.ORDER_PRODUCT_SUCCESS,
  };
};
const orderCompleted = data => {
  return {
    type: actionTypes.ORDER_COMPLETED,
    data,
  };
};
const checkoutError = error => {
  return {
    type: actionTypes.CHECKOUT_ERROR,
    error,
  };
};
export const checkoutErrorClear = () => {
  return {
    type: actionTypes.CHECKOUT_ERROR_CLEAR,
  };
};
export const orderProduct = cart => {
  const products = [];

  cart.forEach(prod => {
    const obj = {
      id: prod.id,
      qty: 1,
      points: prod.points,
    };
    products.push(obj);
  });
  let endpoint = '/catalog/products/request';
  return dispatch => {
    dispatch(orderProductStart());
    customaxios
      .post(endpoint, {
        products: products,
      })
      .then(result => {
        if (result.status === 200) {
          dispatch(orderProductSuccess());
          dispatch(getUserInfo());
        }
      });
  };
};
export const checkout = user => {
  let endpoint = '/catalog/checkout';
  return dispatch => {
    dispatch(orderProductStart());
    return customaxios
      .post(endpoint, user)
      .then(result => {
        if (result.status === 200) {
          dispatch(orderProductSuccess());
          dispatch(orderCompleted(result.data));
          dispatch(getUserInfo());
        }
        return result
      })
      .catch(error => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status >= 400) {
          dispatch(checkoutError(message));
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

export const openOrderModal = product => {
  return {
    type: actionTypes.OPEN_ORDER_MODAL,
    product,
  };
};

export const closeOrderModal = () => {
  return {
    type: actionTypes.CLOSE_ORDER_MODAL,
  };
};

export const clearOrder = () => {
  return {
    type: actionTypes.CLEAR_ORDER,
  };
};

export const getFiltersCategoriesSuccess = categories => {
  return {
    type: actionTypes.GET_FILTERS_CATEGORIES_SUCCESS,
    categories: categories,
  };
};
export const getFiltersBrandsSuccess = brands => {
  return {
    type: actionTypes.GET_FILTERS_BRANDS_SUCCESS,
    brands: brands,
  };
};

export const getFiltersCategories = () => {
  let endpoint = 'catalog/categories';
  return dispatch => {
    customaxios
      .get(endpoint)
      .then(result => {
        if (result.status === 200) {
          dispatch(getFiltersCategoriesSuccess(result.data));
        }
      })
      .catch(error => {});
  };
};

export const getFilterBrands = () => {
  let endpoint = '/catalog/brands';
  return dispatch => {
    customaxios
      .get(endpoint)
      .then(result => {
        if (result.status === 200) {
          dispatch(getFiltersBrandsSuccess(result.data));
        }
      })
      .catch(error => {});
  };
};
